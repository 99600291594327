<template>
    <div>
        <div class="full-width category-container m-t-36">
            <div class="tac container neue neueSettings">
                <Breadcrumbs :path="path" />
            </div>
        </div>
        <div class="full-width p10">
            <div class="tac container flexColumn">
                <h3 class="neue title">EquoMercato</h3>
                <p class="neue neueSettings"><b>Abbigliamento</b></p>

                <center class="only-mobi">
                  <img class="quidLogo" :src="quidLogo.url" alt="" srcset="" style="margin-bottom: 0">
                </center>
            </div>
        </div>
        <div class="full-width m-t-36 p10" style="margin-bottom:3rem;">
            <div id="infoTable" class="container neue neueSettings">
                <div class="container70">
                    <div class="row ">
                        <div class="col-25">
                            <p class="neue neueSettings neueSetBold">Paese</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Italia</p>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Prodotti</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">EquoMercato partecipa alla Cooperative Collection di On Earth con una selezione di borse ed accessori in pelle, stole in vari tessuti, tutti prodotti etici e sostenibili. </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Valore protetto / impegno</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Trasparenza delle relazioni commerciali, prezzo equo, collaborazione con realtà di economia solidale</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Con che paesi lavora</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">EquoMercato collabora con partner in <b>Europa</b>, <b>Asia</b>, <b>Africa</b> e <b>America Latina</b>. Si tratta per la maggior parte di <b>piccole cooperative o realtà produttive che non potrebbero avere accesso al mercato italiano</b> al di fuori dei canali del Commercio Equo e Solidale. Con loro si instaura un rapporto di conoscenza diretta, basato su una collaborazione continuativa e di lunga durata.</p>
                        </div>
                    </div>
                </div>
                <div class="container20">
                    <img class="quidLogo no-mobi" :src="quidLogo.url" alt="" srcset="">
                    <img class="miniMap" :src="miniMap.url" alt="" srcset="">
                    <a class="neue neueSettings" href="https://www.equomercato.it" target="_blank">www.equomercato.it</a>
                </div>
            </div>
        </div>
        <div id="slider" class="mb-6r">
            <VueSlickCarousel v-bind="settings">
                <img src="@/assets/equomercato/1.jpg" alt="">
                <img src="@/assets/equomercato/2.jpg" alt="">
                <img src="@/assets/equomercato/3.jpg" alt="">
                <img src="@/assets/equomercato/4.jpg" alt="">
                <img src="@/assets/equomercato/5.jpg" alt="">
            </VueSlickCarousel>
        </div>
        <div class="full-width mb-4r p10">
            <div class="container flexColumn">
                <div class="row flexColumn">
                    <h3 class="neue title neueSettings">Descrizione</h3>
                    <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">La Cooperativa EquoMercato è una <b>piccola centrale di importazione di prodotti del Commercio Equo e Solidale</b> nata nel 1993 su iniziativa di due volontari appena rientrati in Italia dopo diversi anni di cooperazione in Burundi. L'attività della cooperativa <b>si ispira alla Carta Italiana dei Criteri del Commercio Equo e Solidale</b> e proprio nel rispetto di questi criteri vengono importati prodotti alimentari, cosmetici e di artigianato da diverse piccole cooperative e associazioni di produttori nel mondo. Ai produttori vengono garantiti un <b>prezzo equo</b> dei prodotti acquistati, la <b>continuità del rapporto commerciale</b> e il <b>finanziamento anticipato</b> per un valore pari alla metà della merce ordinata, oltre ad <b>assistenza e cooperazione</b> per lo sviluppo di nuovi prodotti. Alle Botteghe del Mondo e ai consumatori viene garantita la <b>migliore informazione possibile sia sui produttori delle merci vendute sia sui prezzi praticati</b>, attraverso la pubblicazione di schede informative e di prezzi trasparenti per i prodotti. <b>EquoMercato ha avviato</b>, inoltre, <b>diverse collaborazioni con cooperative sociali e soggetti dell'economia solidale e carceraria</b> per realizzare prodotti che utilizzano materie prime importate con i criteri del Commercio Equo.</p>
                </div>   
                <div class="row flexColumn m-t-36">
                    <h3 class="neue title neueSettings">Curiosità</h3>
                    <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">EquoMercato è tra i soci fondatori di EQUO GARANTITO, già AGICES, l'Assemblea Generale Italiana del Commercio Equo e Solidale.</p>
                </div> 
            </div>
        </div>
        <ProductSlider supplier="EQ" />
    </div>
</template>
<script>
    import Breadcrumbs from '@/components/Breadcrumbs'
    import ProductSlider from '@/components/ProductSlider'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    const equoLogo = require('../assets/equomercato/equo.png')

    export default {
        name : 'EquoMercato',
        components : {
            Breadcrumbs,
            ProductSlider,
            VueSlickCarousel
        },


        metaInfo: {
          title: 'EquoMercato'
        },

        data() {
            return {
                path: [
                    { label: 'Home', path: '/' },
                    { label: 'Brand', path: '/brand' },
                    { label: 'EquoMercato', path: '/equo-mercato' }
                ],
                miniMap : {
                    url : 'https://www.altromercato.it/wp-content/uploads/2021/09/Bosnia-ed-Erzegovina.jpg'
                },
                quidLogo : {
                    url: equoLogo
                },
                settings : {
                    "arrows": true,
                    "dots": false,
                    "infinite": true,
                    "slidesToShow": 4,
                    "slidesToScroll": 1,
                    "speed": 500,
                    "cssEase": "linear",
                    "responsive": [
                        {
                            "breakpoint": 668,
                            "settings": {
                                "speed": 500,
                                "slidesToShow": 2,
                                "slidesToScroll": 2,
                                "infinite": true,
                                "dots": false
                            }
                        },
                    ]
                }
            }
        }
    }

</script>
<style >
    #slider .slick-next {
        right: 67px;
    }
    #slider .slick-prev {
        left: 67px;
        z-index: 1;
    }
    .mb-4r{
        margin-bottom: 4rem;
    }
    .mb-6r{
        margin-bottom: 6rem;
    }
    .neueSettings{
        letter-spacing: 0.5px;
        line-height: 1.5em;
    }
    .miniMap{
        max-width: 100%;
        border: 0.5px solid rgba(128, 128, 128, 0.2);
        padding: 10px;
        margin-bottom: 2rem;
    }
    .quidLogo{
        max-width: 50%;
        margin-bottom: 2rem;
    }
    .title{
        font-size: 38px;
        font-weight: 600;
        letter-spacing: 0.4px;
        margin: 0;
    }
    .container70{
        max-width: 75%;
    }
    .container20{
        max-width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateY(-120px);
    }
    .neueSetBold{
        font-weight: 600;
    }
    .flexColumn{
        flex-direction: column;
    }
    .col-25{
        width: 30%;
        max-width: 30%;
    }
    .col-50{
        width: 50%;
        max-width: 50%;
    }
    .col-60{
        width: 60%;
        max-width: 60%;    
    }
    .col-75{
        width: 75%;
        max-width: 75%;
    }
    @media(max-width: 668px){
        #infoTable{
            flex-direction: column;
        }
        #infoTable .container70{
            max-width: 100%;
        }
        #infoTable .container20{
            transform: unset;
            max-width: 100%;
        }
        #infoTable .quidLogo{
            max-width: 20%;
            margin-top: 2rem;
        }
        #infoTable a{
            margin-bottom: 4rem;
        }
        .col_sm-100{
            max-width: 100%;
            width: 100%;
        }
        .p10{
            padding: 10px;
        }
        .tac{
            text-align: center;
        }
    }
</style>
